import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const LoadingScreen = () => {
  return (
    <View>
      <Text>Loading....</Text>
    </View>
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({});
