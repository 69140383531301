import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

const GameCard = ({ game, navigation }) => {
  if (game.empty === true) {
    return <View style={[styles.item, styles.itemInvisible]} />;
  }
  return game.title === 'Coming Soon' ? (
    <View style={[styles.item, { backgroundColor: game.color }]}>
      <FontAwesome5 style={styles.icon} name={game.icon} />
      <Text style={styles.itemText}>{game.title}</Text>
    </View>
  ) : (
    <TouchableOpacity
      onPress={() => navigation.navigate('Game', { type: game.type })}
      style={[styles.item, { backgroundColor: game.color }]}
    >
      <FontAwesome5 style={styles.icon} name={game.icon} />
      <Text style={styles.itemText}>{game.title}</Text>
    </TouchableOpacity>
  );
};

export default GameCard;

const styles = StyleSheet.create({
  itemText: {
    marginTop: 20,
    color: 'white',
    fontSize: 20,
  },
  icon: {
    color: 'white',
    fontSize: 50,
  },
  item: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: 250,
  },
  itemInvisible: {
    backgroundColor: 'transparent',
  },
});
