import React from 'react';
import { StyleSheet, Text, View, FlatList } from 'react-native';
import GameCard from './GameCard';

const randomColor = () =>
  `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)})`;

const data = [
  {
    id: '1',
    icon: 'grin-squint-tears',
    title: 'Yemilikit Chewata',
    color: 'green',
    type: 'milikit',
  },
  {
    id: '2',
    icon: 'question',
    title: 'Coming Soon',
    color: randomColor(),
    type: 'none',
  },
  {
    id: '3',
    icon: 'question',
    title: 'Coming Soon',
    color: randomColor(),
    type: 'none',
  },
];
const numColumns = 2;

const formatData = (data, numColumns) => {
  const numberOfFullRows = Math.floor(data.length / numColumns);

  let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
  while (
    numberOfElementsLastRow !== numColumns &&
    numberOfElementsLastRow !== 0
  ) {
    data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
    numberOfElementsLastRow++;
  }

  return data;
};

const Games = ({ navigation }) => {
  const renderItem = ({ item }) => {
    return <GameCard game={item} navigation={navigation} />;
  };

  return (
    <FlatList
      data={formatData(data, numColumns)}
      style={styles.container}
      renderItem={renderItem}
      numColumns={numColumns}
      keyExtractor={(item) => item.id}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  );
};

export default Games;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
