import React from 'react';
import { TouchableOpacity } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { auth } from '../api/firebase';

import { FontAwesome5 } from '@expo/vector-icons';

// Screens
import GameStartScreen from '../screens/GameStartScreen';
import LoadingScreen from '../screens/LoadingScreen';
import ModalScreen from '../screens/ModalScreen';
import GameSelectScreen from '../screens/GameSelectScreen';
import HomeScreen from '../screens/HomeScreen';
import CustomDrawerContent from './CustomDrawerContent';
import SettingsScreen from '../screens/SettingsScreen';

const RootStack = createStackNavigator();
const RootStackScreen = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [user, setUser] = React.useState(null);

  // Home Stack
  const StartStack = createStackNavigator();
  const StartStackScreen = () => (
    <StartStack.Navigator
      initialRouteName="Main"
      screenOptions={{
        headerShown: false,
      }}
    >
      <StartStack.Screen name="Home" component={HomeScreen} />
      <StartStack.Screen name="Main" component={MainDrawerScreen} />
      <StartStack.Screen
        options={({ navigation }) => ({
          headerShown: true,
          headerTitle: 'back',
          headerTransparent: true,
          headerStyle: { borderBottomWidth: 0 },
          headerTitleStyle: { color: 'white' },
          headerLeft: () => (
            <TouchableOpacity
              onPress={() => navigation.navigate('Main')}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{ marginHorizontal: 16 }}
            >
              <FontAwesome5
                name="arrow-alt-circle-left"
                size={30}
                style={{ color: 'white' }}
              />
            </TouchableOpacity>
          ),
        })}
        name="Game"
        component={GameStartScreen}
      />
    </StartStack.Navigator>
  );

  const MainDrawer = createDrawerNavigator();
  const MainDrawerScreen = () => (
    <MainDrawer.Navigator
      drawerPosition="left"
      drawerType="permanent"
      drawerStyle={{ width: 150 }}
      initialRouteName="Home"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <MainDrawer.Screen
        name="Categories"
        component={GameSelectScreen}
        options={{
          drawerLabel: 'Select Game',
        }}
      />
      <MainDrawer.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          drawerLabel: 'Settings',
        }}
      />
    </MainDrawer.Navigator>
  );

  React.useEffect(() => {
    auth().signInAnonymously();
    auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setIsLoading(false);
        setUser(authUser);
      } else {
        // No user is signed in.
        setIsLoading(false);
        setUser(null);
      }
    });
  }, []);

  return (
    <RootStack.Navigator
      headerMode="none"
      screenOptions={{ animationEnabled: false }}
      mode="modal"
    >
      {isLoading ? (
        <RootStack.Screen name="Loading" component={LoadingScreen} />
      ) : user ? (
        <RootStack.Screen name="Start" component={StartStackScreen} />
      ) : (
        <RootStack.Screen name="Loading" component={LoadingScreen} />
      )}
      <RootStack.Screen
        name="Modal"
        component={ModalScreen}
        options={{ animationEnabled: true }}
      />
    </RootStack.Navigator>
  );
};

export default () => {
  return (
    <NavigationContainer>
      <RootStackScreen />
    </NavigationContainer>
  );
};
