import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const ModalScreen = ({ navigation }) => (
  <View style={styles.container}>
    <TouchableOpacity
      style={styles.modalButton}
      onPress={() => navigation.pop()}
    >
      <Text>Modal me</Text>
    </TouchableOpacity>
  </View>
);

export default ModalScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: { backgroundColor: 'white', padding: 20 },
});
