import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Audio } from 'expo-av';

function useInterval(callback, delay, count, done) {
  const savedCallback = useRef();
  let id = null;

  useEffect(() => {
    if (count === 0) {
      clearInterval(id);
      done();
    }
  }, [count]);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      id = setInterval(tick, delay);
      return () => clearInterval(id);
    } else {
      clearInterval(id);
    }
  }, [delay]);
}

const CountDown = ({
  start = false,
  interval = 1000,
  seconds,
  done,
  beep = false,
}) => {
  let [count, setCount] = useState(seconds);
  let [delay, setDelay] = useState(interval);
  let beepSoundObject = new Audio.Sound();
  let soundLoaded = null;

  useEffect(() => {
    const loadSound = async () => {
      soundLoaded = await beepSoundObject.loadAsync(
        require('../assets/audio/bleep.mp3')
      );
    };
    loadSound();
  }, [start]);

  const playBeep = async () => {
    try {
      if (count > 1) {
        if (!soundLoaded)
          soundLoaded = await beepSoundObject.loadAsync(
            require('../assets/audio/bleep.mp3')
          );
        await beepSoundObject.playAsync();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(
    () => {
      if (start) {
        if (count > 0) {
          setCount(count - 1);
          if (beep) {
            playBeep();
          }
        }
      }
    },
    delay,
    count,
    done
  );

  return (
    <View>
      <Text style={styles.counterText}>{count}</Text>
    </View>
  );
};

export default CountDown;

const styles = StyleSheet.create({
  counterText: {
    fontSize: 20,
    color: 'white',
  },
});
