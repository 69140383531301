import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import CountDown from '../CountDown';
import Milikit from './Milikit';

import { firestore } from '../../api/firebase';

const Ready = ({ ready, onReady }) => {
  const [start, setStart] = useState(false);
  return !ready ? (
    <TouchableOpacity style={styles.start} onPress={() => setStart(true)}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <CountDown
          start={start}
          beep
          seconds={5}
          done={() => {
            setStart(false);
            onReady();
          }}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 50 }}>
          Touch Screen To Start Game
        </Text>
      </View>
    </TouchableOpacity>
  ) : null;
};

const GameStage = ({ route }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    loadData();
  }, []);

  const { type } = route.params;

  const loadData = async () => {
    switch (type) {
      case 'milikit':
        try {
          const querySnapshot = await firestore().collection('words').get();

          if (!querySnapshot.empty) {
            const items = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists) items.push({ id: doc.id, ...doc.data() });
            });
            setData(items);
          }
        } catch (error) {
          console.log(error.message);
        }

        break;

      default:
        break;
    }
  };
  const [ready, setReady] = useState(false);
  return (
    <View style={styles.container}>
      <Ready ready={ready} onReady={() => setReady(true)} />
      {type === 'milikit' && <Milikit ready={ready} data={data} />}
    </View>
  );
};

export default GameStage;

const styles = StyleSheet.create({
  container: { flex: 1 },
  start: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
});
