import React, { useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  Dimensions,
} from 'react-native';

const screen = Dimensions.get('screen');

const HomeScreen = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Text>MEDEBERYA.COM</Text>
      </View>
      <View style={styles.types}>
        <TouchableOpacity
          style={{
            flex: 1,
            backgroundColor: 'green',
            alignItems: 'center',
            justifyContent: 'center',
            width: screen.width,
          }}
          onPress={() => navigation.navigate('Main', { mode: 'quick' })}
        >
          <Text style={{ color: 'white', fontSize: 40, paddingVertical: 40 }}>
            Quick Play
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            flex: 1,
            backgroundColor: 'gray',
            alignItems: 'center',
            justifyContent: 'center',
            width: screen.width,
          }}
          onPress={() => navigation.navigate('Main', { mode: 'versus' })}
        >
          <Text style={{ color: 'white', fontSize: 40, paddingVertical: 40 }}>
            Versus
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'yellow',
    alignItems: 'center',
    justifyContent: 'center',
  },
  types: {
    width: screen.width,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
