import React from 'react';
import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import Animated from 'react-native-reanimated';

const CustomDrawerContent = ({ progress, ...rest }) => {
  const translateX = Animated.interpolate(progress, {
    inputRange: [0, 1],
    outputRange: [-100, 0],
  });

  return (
    <DrawerContentScrollView {...rest}>
      <Animated.View style={{ transform: [{ translateX }] }}>
        <DrawerItem
          label="Home"
          onPress={() => rest.navigation.navigate('Home')}
        />
        <DrawerItemList {...rest} />
      </Animated.View>
    </DrawerContentScrollView>
  );
};

export default CustomDrawerContent;
