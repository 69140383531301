import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import Games from '../components/Games';

const screen = Dimensions.get('screen');

const GameSelectScreen = (props) => {
  return (
    <View style={styles.container}>
      <Games {...props} />
    </View>
  );
};

export default GameSelectScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
