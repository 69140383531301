import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import Navigation from './src/navigation';
import { changeScreenOrientation } from './src/helpers';

export default () => {
  useEffect(() => {
    changeScreenOrientation();
  }, []);

  return (
    <>
      <StatusBar hidden />
      <Navigation />
    </>
  );
};
