import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import GameStage from '../components/Games/GameStage';

const GameStartScreen = (props) => {
  return (
    <View style={styles.container}>
      <GameStage {...props} />
    </View>
  );
};

export default GameStartScreen;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#8389e2' },
});
