const firebaseConfig = {
  apiKey: 'AIzaSyAEqCKiyDEzG3zNianUrT7zchcuvYRKzNU',
  authDomain: 'medeberya-60de5.firebaseapp.com',
  databaseURL: 'https://medeberya-60de5.firebaseio.com',
  projectId: 'medeberya-60de5',
  storageBucket: 'medeberya-60de5.appspot.com',
  messagingSenderId: '443816546192',
  appId: '1:443816546192:web:89aafafd4f530db448ed15',
  measurementId: 'G-KJYDG74JVT',
};

export default firebaseConfig;
