import React, { useState, useEffect } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  FlatList,
} from 'react-native';
import { FontAwesome5 as Icon } from '@expo/vector-icons';
import { sample, remove } from 'lodash';
import { Audio } from 'expo-av';

import CountDown from '../../CountDown';

const screen = Dimensions.get('screen');

const ResultList = ({ result = [], header }) => {
  const renderItem = ({ item }) => {
    return (
      <Text
        style={{
          fontWeight: 'bold',
          color: header === 'Correct' ? 'green' : '#8B0000',
        }}
      >
        {item.word}
      </Text>
    );
  };

  return (
    <FlatList
      ListHeaderComponent={
        <Text
          style={styles.resultHeader}
        >{`${header} (${result.length})`}</Text>
      }
      data={result}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const Result = ({ correct = [], incorrect = [], gameOver }) => {
  let soundObjectWin = new Audio.Sound();
  let soundObjectLose = new Audio.Sound();
  let winSoundLoaded = null;
  let loseSoundLoaded = null;

  useEffect(() => {
    const loadSounds = async () => {
      winSoundLoaded = await soundObjectWin.loadAsync(
        require('../../../assets/audio/crowd.mp3')
      );
      loseSoundLoaded = await soundObjectLose.loadAsync(
        require('../../../assets/audio/sad.mp3')
      );
    };
    loadSounds();
  }, []);

  const playWin = async () => {
    try {
      if (!winSoundLoaded)
        winSoundLoaded = await soundObjectWin.loadAsync(
          require('../../../assets/audio/crowd.mp3')
        );
      await soundObjectWin.playAsync();
    } catch (error) {
      console.log(error);
    }
  };

  const playLose = async () => {
    try {
      if (!loseSoundLoaded)
        loseSoundLoaded = await soundObjectLose.loadAsync(
          require('../../../assets/audio/sad.mp3')
        );
      await soundObjectLose.playAsync();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const playSound = async () => {
      if (correct.length === incorrect.length && correct.length > 0) {
        playWin();
        return;
      }

      if (correct.length > incorrect.length) {
        playWin();
      } else {
        playLose();
      }
    };
    if (gameOver) {
      playSound();
    }
  }, [gameOver]);
  return gameOver ? (
    <View style={styles.resultWrapper}>
      <View style={styles.resultItem}>
        <ResultList result={correct} header="Correct" />
      </View>
      <View style={styles.resultItem}>
        <ResultList result={incorrect} header="Incorrect" />
      </View>
    </View>
  ) : null;
};

const Milikit = ({ ready, data = [] }) => {
  const [start, setStart] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [correct, setCorrect] = useState([]);
  const [incorrect, setIncorrect] = useState([]);

  let correctSoundObject = new Audio.Sound();
  let correctSoundLoaded = null;

  let inCorrectSoundObject = new Audio.Sound();
  let inCorrectSoundLoaded = null;

  useEffect(() => {
    const loadSounds = async () => {
      correctSoundLoaded = await correctSoundObject.loadAsync(
        require('../../../assets/audio/correct.mp3')
      );
      inCorrectSoundLoaded = await inCorrectSoundObject.loadAsync(
        require('../../../assets/audio/incorrect.mp3')
      );
    };
    loadSounds();
  }, []);

  const playCorrect = async () => {
    try {
      if (!correctSoundLoaded)
        correctSoundLoaded = await correctSoundObject.loadAsync(
          require('../../../assets/audio/correct.mp3')
        );
      await correctSoundObject.playAsync();
    } catch (error) {
      console.log(error);
    }
  };

  const playInCorrect = async () => {
    try {
      if (!inCorrectSoundLoaded)
        inCorrectSoundLoaded = await inCorrectSoundObject.loadAsync(
          require('../../../assets/audio/incorrect.mp3')
        );
      await inCorrectSoundObject.playAsync();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data.length) {
      setStart(true);
      setQuestions(data);
    }
  }, [data]);

  useEffect(() => {
    nextQuestion();
  }, [questions]);

  const nextQuestion = () => {
    if (questions.length) {
      const q = sample(questions);
      setCurrentQuestion(q);
      remove(data, q);
    }
  };

  return ready ? (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Result correct={correct} incorrect={incorrect} gameOver={gameOver} />

        {start && (
          <CountDown
            start={start}
            seconds={60}
            done={() => {
              setStart(false);
              setGameOver(true);
            }}
          />
        )}
      </View>
      {start && (
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <View style={{ flex: 1 }}>
            <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 50 }}>
              {currentQuestion && currentQuestion.word}
            </Text>
          </View>
          <View style={styles.buttonWrapper}>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: 'green' }]}
              onPress={() => {
                playCorrect();
                setCorrect([...correct, currentQuestion]);
                nextQuestion();
              }}
            >
              <Icon name="check" color="white" size={70} />
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.button, { backgroundColor: '#8B0000' }]}
              onPress={() => {
                playInCorrect();
                setIncorrect([...incorrect, currentQuestion]);
                nextQuestion();
              }}
            >
              <Icon name="times" color="white" size={70} />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  ) : null;
};

export default Milikit;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  button: {
    width: screen.width / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#A8ACEA',
  },
  resultWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 60,
  },
  resultItem: {
    width: screen.width / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  resultHeader: {
    fontSize: 30,
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 5,
  },
});
